import React, { useRef, useState } from "react";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import logo2 from "../images/bomfav.PNG";
import insta from "../images/insta.svg";
import Loader from "react-loader-spinner";

export default function Footer() {
  const emailRef = useRef();

  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  function handleJoin(e) {
    e.preventDefault();
    if (emailRef.current.value.length < 5) {
      setErr(
        (err = "Please enter a valid email ")
      );
      return;
    }
    setLoading(true);

    // const l = "http://localhost:5001/bomchi-39029/us-central1/app/api/email";
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/email";
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setErr(
          (err = "Thanks for signing up 😬")
        );
        // use => this.email.current.value, ref={this.email} instead of dom
        document.getElementById("email").reset();
        setLoading(false);

        // if (data.status === "success") {
        //   emailRef.current.value = "";
        // }
      })
      .catch((err) => {
        setLoading((loading = false));
        console.log(err);
      });
  }
  return (
    <>
      {/* <Row>
          <Col sm={8}>sm=8</Col>
          <Col sm={4}>sm=4</Col>
        </Row> */}
      <Row className="footer">
        <Col sm>
          <div className="fm">
            <Image src={logo2} width="300px" />
          </div>
        </Col>

        <Col sm>
          <div className="fm fu">
            <h3 className>Follow us</h3>
            <a
              href="https://www.instagram.com/bomchichickenandchips/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <Image src={insta} width="50px" />
            </a>
          </div>
        </Col>

        <Col sm>
          <div className="fm">
            <h3>Footer Menu</h3>
            <ul className="fm1">
              <li>
                <Link to="/"> HOME</Link>
              </li>
              {/* <li>
                <Link to="/about"> ABOUT</Link>
              </li> */}
              <li>
                <Link to="/gallery">
                  {" "}
                  GALLERY
                </Link>
              </li>
              <li>
                <Link to="/entertainer">
                  {" "}
                  ENTERTAINERS
                </Link>
              </li>
              <li>
                <Link to="/vendor">
                  {" "}
                  BECOME A VENDOR
                </Link>
              </li>
              <li>
                <Link to="/ticket">
                  <Button> BUY TICKETS </Button>
                </Link>
              </li>
              {/* <li>
                <Link to="/chicken">
                  <Button>
                    {" "}
                    BUY CHICKEN & CHIPS{" "}
                  </Button>
                </Link>
              </li> */}

              {/* <li>
                <Link to='/vendor'>
                  <span className='vendor'> BECOME A VENDOR </span>
                </Link>
              </li> */}
            </ul>
          </div>
        </Col>

        <Col sm>
          <div className="fm">
            <h3>Sign up for our newsletter </h3>
            <p>
              Stay updated with the latest
              information from Bomchi
            </p>
            <Form
              id="email"
              onSubmit={handleJoin}
            >
              <Form.Control
                ref={emailRef}
                className="mb"
                size="lg"
                type="email"
                placeholder="Email Address"
                required
              />
              <div
                className="d-grid gap-2"
                style={{ marginBottom: "20px" }}
              >
                {err.length > 0 && <p>{err}</p>}

                <Button
                  type="submit"
                  disabled={loading}
                  variant="secondary"
                  size="lg"
                >
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="#fff"
                        height="30"
                        width="30"
                      />
                    </div>
                  ) : (
                    "JOIN"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <div className="footer-text">
          <p style={{ marginTop: "3px" }}>
            © 2023 ChillwithBomchi | Developed by{" "}
            <span>Donald Nyingifa</span>
          </p>
        </div>
      </Row>
    </>
  );
}
