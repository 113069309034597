import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import logo from "../images/blogo3.png";
import home from "../images/house.png";
import chips from "../images/chips.png";
import imggallery from "../images/image-gallery.png";
import video from "../images/video.png";
import tickets from "../images/tickets.png";
import vendor from "../images/vendor.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Navbar className="fixed" expand={false}>
      <Container fluid>
        <Navbar.Brand href="#">
          <Link to="/">
            {" "}
            <Image src={logo} width="100vw"/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              <Image src={logo} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3">
              <ul>
                <li>
                  <Link to="/">
                    <Image
                      src={home}
                      style={{
                        marginBottom: "10px",
                      }}
                    />{" "}
                    HOME
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about"> ABOUT</Link>
                </li> */}
                <li>
                  <Link to="/gallery">
                    <Image
                      src={imggallery}
                      style={{
                        marginBottom: "10px",
                      }}
                    />{" "}
                    GALLERY
                  </Link>
                </li>
                <li>
                  <Link to="/entertainer">
                    <Image
                      src={video}
                      style={{
                        marginBottom: "10px",
                      }}
                    />{" "}
                    ENTERTAINERS{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/ticket">
                    <Button>
                      <Image
                        src={tickets}
                        style={{
                          marginBottom: "10px",
                        }}
                      />{" "}
                      BUY TICKETS{" "}
                    </Button>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/chicken">
                    <Button>
                      <Image
                        src={chips}
                        style={{
                          marginBottom: "10px",
                        }}
                      />{" "}
                      BUY CHICKEN & CHIPS{" "}
                    </Button>
                  </Link>
                </li> */}
                <li>
                  <Link to="/vendor">
                    <span className="vendor">
                      <Image
                        src={vendor}
                        style={{
                          marginBottom: "10px",
                          paddingRight: "5px",
                        }}
                      />{" "}
                      BECOME A VENDOR{" "}
                    </span>
                  </Link>
                </li>
              </ul>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
