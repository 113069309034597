import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header.js";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import jwtDecode from "jwt-decode";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

function Admin() {
  let [log, setLog] = useState("");
  let [loading, setLoading] = useState(false);
  let [user, setUser] = useState({});
  let [err, setErr] = useState("");

  const passwordRef = useRef();
  const nameRef = useRef();
  const name1Ref = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    const jwt = localStorage.getItem("token");
    if (jwt) {
      const user = jwtDecode(jwt);
      setUser(user);
      // console.log("user", user);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (nameRef.current.value.length < 5) {
      setErr(
        (err = "Please enter a valid reference ")
      );
      return;
    }
    try { //
      window.open(
        `https://chillwithbomchi.com/verify/${nameRef.current.value}`,
        "_blank"
      );
    } catch (err) {
      // console.log(err);
      setLoading(false);
      setErr(
        "Please check your internet connection and try again"
      );
    }
  }

  function handleLogout() {
    (async function () {
      setLog((log = "none"));
      setUser((user = false));
      await localStorage.removeItem("token");
      await sessionStorage.removeItem("token");
    })();
    return;
  }

  async function handleSignin() {
    setLoading(true);
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/auth";
    // const l =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/auth";
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name1Ref.current.value.toUpperCase(),
        password: passwordRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
        if (data.token !== undefined) {
          localStorage.setItem(
            "token",
            data.token
          );
          const jwt =
            localStorage.getItem("token");
          const user = jwtDecode(jwt);
          setUser(user);
          setLog((log = ""));

          setLoading((loading = false));
          setErr((err = ""));
          window.location = "/admin22";
        } else {
          setLoading((loading = false));
          setErr("Invalid login details");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading((loading = false));
        setErr(
          `Make sure your details are correct 
          and you're connected to the internet `
        );
      });
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        minHeight: "100vh",
      }}
    >
      <Header />
      <div className="vendor centerDiv">
        {!user._name && (
          <>
            <Form className="signin">
              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  ref={name1Ref}
                  placeholder="Name"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicPassword"
              >
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Password"
                />
              </Form.Group>

              <Button
                variant="primary"
                disabled={loading || user._name}
                onClick={handleSignin}
              >
                Submit
              </Button>
            </Form>
            {err.length > 0 && (
              <p
                style={{
                  padding: "20px",
                }}
              >
                {" "}
                {err}
              </p>
            )}
          </>
        )}
        <div>
          {user._name && (
            <>
              {(user._name === "BOMCHIADMIN1" ||
                user._name === "DONALD") && (
                  <>
                    <Row
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <Col>
                        <Link to="/vv">
                          <div className="adview">
                            <h4>
                              VIEW <br /> VENDORS
                            </h4>
                          </div>
                        </Link>
                      </Col>

                      <Col>
                        <Link to="/vp">
                          <div className="adview">
                            <h4>
                              VIEW <br />
                              PERFORMERS
                            </h4>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/ve">
                          <div className="adview">
                            <h4>
                              VIEW <br />
                              ENQUIRES
                            </h4>
                          </div>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/vu">
                          <div className="adview">
                            <h4>
                              VIEW <br />
                              USERS
                            </h4>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/cu">
                          <div className="adview">
                            <h4>CREATE USER</h4>
                          </div>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/ct">
                          <div className="adview">
                            <h4>CREATE TICKET</h4>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
              <Row>
                <Col>
                  <Link to="/vt">
                    <div className="adview">
                      <h4>VIEW TICKETS</h4>
                    </div>
                  </Link>
                </Col>
              </Row>
              <Form
                style={{
                  display: log,
                  marginTop: "20px",
                }}
                onSubmit={handleSubmit}
                className="vendorform  "
              >
                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                >
                  <Form.Label>
                    <h3>
                      Verify and Issue Ticket
                      (Reference)
                    </h3>
                  </Form.Label>
                  <Form.Control
                    ref={nameRef}
                    type="text"
                    placeholder="Reference Number"
                    required
                  />
                </Form.Group>

                <Button
                  style={{
                    display: "block",
                    margin: "auto",
                  }}
                  type="submit"
                  disabled={loading}
                >
                  SUBMIT
                </Button>
                {loading && (
                  <div
                    style={{
                      width: "100%",
                      height: "100",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader
                      type="ThreeDots"
                      color="#2BAD60"
                      height="100"
                      width="100"
                    />
                  </div>
                )}
                {err.length > 0 && (
                  <p
                    style={{
                      padding: "20px",
                    }}
                  >
                    {" "}
                    {err}
                  </p>
                )}
              </Form>
              <div
                style={{
                  backgroundColor:
                    "rgba(0,0,0,0.7)",
                  padding: "30px",
                  display: log,
                }}
              >
                <h3>
                  Hi {user._name} you are loggedIn
                </h3>
                <Button
                  variant="warning"
                  onClick={handleLogout}
                >
                  LOGOUT
                </Button>
              </div>
            </>
          )}
        </div>
        {/* {console.log(user)} */}
      </div>
    </div>
  );
}

export default Admin;
