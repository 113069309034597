import React, { useEffect } from "react";
import Header from "../components/header";
import Body from "../components/body";
import Footer from "../components/footer";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
}

export default Home;
