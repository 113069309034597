import React, { useState, useEffect } from "react";

const eventDate = "Dec, 23, 2024  13:00:00 GMT";

const TimeUntil = () => {
  const [countdownState, setCountdownState] = useState({
    deadline: Date.parse(eventDate),
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const timeUntil = countdownState.deadline - Date.now();
      if (timeUntil < 0) {
        return;
      }

      const seconds = Math.floor((timeUntil / 1000) % 60);
      const minutes = Math.floor((timeUntil / 1000 / 60) % 60);
      const hours = Math.floor((timeUntil / (1000 * 60 * 60)) % 24);
      const days = Math.floor(timeUntil / (1000 * 60 * 60 * 24));

      setCountdownState({
        ...countdownState,
        days,
        hours,
        minutes,
        seconds,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownState, countdownState.deadline]);

  return (
    <>
      {countdownState.deadline < Date.now() ? (
        <h2 style={{ paddingTop: "5px" }}>WE ARE LIVE 🔥</h2>
      ) : (
        <div className="countdown_wrapper">
          <div className="countdown_top">Event starts in</div>

          <div className="countdown_bottom">
            <div className="countdown_item">
              <div className="countdown_time">{countdownState.days}</div>
              <div
                style={{ paddingRight: "5px" }}
                className="countdown_tag"
              >
                Days
              </div>
            </div>

            <div className="countdown_item">
              <div className="countdown_time">{countdownState.hours}</div>
              <div className="countdown_tag">Hs</div>
            </div>

            <div className="countdown_item">
              <div className="countdown_time">{countdownState.minutes}</div>
              <div className="countdown_tag">Min</div>
            </div>

            <div className="countdown_item">
              <div className="countdown_time">{countdownState.seconds}</div>
              <div className="countdown_tag">Sec</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimeUntil;
