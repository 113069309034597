import React, { useEffect } from "react";
import Header from "../components/header.js";
import Footer from "../components/footer.js";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header />
        <div
          style={{
            minHeight: "50vh",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <h2
            style={{
              color: "#fff",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "200px",
            }}
          >
            {" "}
            404
          </h2>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default NotFound;
