import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import Header from "../components/header.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function CreateUser() {
  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nameRef = useRef();
  const passRef = useRef();
  const confirmRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    if (
      nameRef.current.value.length < 2 ||
      confirmRef.current.value !=
        passRef.current.value
    ) {
      setErr(
        (err =
          "Please make sure the password is the same then try again!")
      );
      return;
    }
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/regxyz";
    // const l =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/regxyz";

    setLoading((loading = true));
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: nameRef.current.value.toUpperCase(),
        password: passRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("response data", data);

        setErr(
          (err =
            nameRef.current.value +
            " was created successfully.")
        );
        document.getElementById("user").reset();
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        setLoading(false);
        setErr(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        minHeight: "100vh",
      }}
    >
      <Header />
      <div className="vendor centerDiv ">
        <h1>ADD ADMIN USER </h1>

        <Form
          id="user"
          onSubmit={handleSubmit}
          className="vendorform centerDiv "
        >
          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={nameRef}
              type="text"
              placeholder="Name"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicLink"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={passRef}
              type="password"
              placeholder="Password"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicPhone"
          >
            <Form.Label>
              Confirm Password
            </Form.Label>
            <Form.Control
              ref={confirmRef}
              type="password"
              placeholder="Confirm Password"
              required
            />
          </Form.Group>

          {err.length > 0 && (
            <p
              style={{
                backgroundColor:
                  "rgba(0,0,0,0.7)",
                padding: "20px",
              }}
            >
              {" "}
              {err}
            </p>
          )}
          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#fff"
                  height="30"
                  width="30"
                />
              </div>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default CreateUser;
