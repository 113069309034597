import React, { useRef, useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Image from "react-bootstrap/Image";
import logo from "../images/blogo3.png";
import Container from "react-bootstrap/Container";
import Loader from "react-loader-spinner";
import QRCode from "react-qr-code";

const words = {
  1: "ONE (1)",
  2: "TWO (2)",
  3: "THREE (3)",
  4: "FOUR (4)",
  5: "FIVE (5)",
  6: "SIX (6)",
  7: "SEVEN (7)",
  8: "EIGHT (8)",
  9: "NINE (9)",
  10: "TEN (10)",
};

function Ticket() {
  const ticRef = useRef();
  const [verified, setVerified] = useState(false);
  const [verChicken, setVerChicken] = useState(false);
  const [verifyFail, setVerifyFail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [amountPaid, setAmount] = useState(0);
  const [customerName, setName] = useState("");
  const [customerEmail, setEmail] = useState("");
  const [ref, setRef] = useState("");
  const [type, setType] = useState("");
  const [qty, setQuantity] = useState("");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(
      window.location.search
    );
    const params = Object.fromEntries(
      urlSearchParams.entries()
    );
    const { reference } = params;

    fetch(
      // `http://localhost:5001/bomchi-39029/us-central1/app/api/pay/verify/${reference}`
      `https://us-central1-bomchi-39029.cloudfunctions.net/app/api/pay/verify/${reference}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.type === "chicken&chips") {
          setVerChicken(true);
          setName(data.name);
          setEmail(data.email);
          setRef(data.ref);
          setQuantity(data.qty);
          setAmount(data.amountPaid);
          emailChicken();
        } else if (data.status) {
          setVerified(true);
          setName(data.fname);
          setEmail(data.email);
          setRef(data.ref);
          setType(data.type);
          setQuantity(data.qty);
          // handleEmail(data.email, data.ref, data.type, data.fname, data.qty);
        }
      })
      .catch((err) => {
        setVerifyFail(true);
      })
      .finally(() => setLoading(false));
  });

  function handleEmail(email, ref, type, customerName, qty) {
    const online =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/emailTicket";

    // const local =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/emailTicket";

    fetch(online, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        ref,
        type,
        customerName,
        qty,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err, "email");
      });
  }

  function emailChicken() {
    const online =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/emailchicken";

    // const local =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/emailchicken";

    fetch(online, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: customerEmail,
        ref,
        customerName,
        qty,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err, "email");
      });
  }

  return (
    <>
      <Header />
      <div className="tics">
        <Container>
          <div className="ticstat">
            <Image
              src={logo}
              style={{
                maxWidth: "50%",
                margin: "auto",
              }}
            />
            {loading && (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#2BAD60"
                  height="100"
                  width="100"
                />
              </div>
            )}
            {verified && (
              <div ref={ticRef} style={{ marginLeft: "30px", padding: '20px' }}>
                <h6 style={{ textTransform: "uppercase"}}>
                  Ticket: <span className="k1">BOMCHI_{customerName}_{qty}</span>
                </h6>
                <div id="qrcode" style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <QRCode size={190} value={`https://chillwithbomchi.com/verify/${ref}`} />
                </div><br />
                <p>No of Tickets: <strong style={{ fontSize: '18px', fontWeight: 'bolder' }}>{words[qty]}</strong></p>
              </div>
            )}

            {verChicken && (
              <div ref={ticRef} style={{ marginLeft: "30px" }}>
                <h6 style={{ textTransform: "uppercase" }}>Receipt</h6>
                <p>{customerName}</p>
                <p>PAID ₦{amountPaid}</p>
                <p>FOR</p>
                <p>{qty} chicken&chips</p>
                <div id="qrcode" style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <QRCode size={150} value={`https://chillwithbomchi.com/verifychicken/${ref}`} />
                </div>
              </div>
            )}

            {verifyFail && !verified && (
              <div style={{ marginLeft: "30px", padding: "30px" }}>
                <p>
                  Unable to verify payment, if you do not receive your tickets via email, fill the enquiry form on the home page.
                </p>
              </div>
            )}

            {!loading && verChicken && (
              <div style={{ marginLeft: "30px", padding: "30px" }}>
                <p>You can refresh this page if your receipt is not displayed.</p>
                <p>This receipt is also emailed to you</p>
              </div>
            )}

            {!loading && !verChicken && (
              <div style={{ marginLeft: "30px", padding: "20px", fontSize: "12px" }}>
                <span>
                  Your ticket has been emailed to you. It should also be displayed on this page.
                </span><br />
                <em style={{ color: 'red' }}>
                  This ticket is invalid after it is scanned at the venue
                </em>
              </div>
            )}

          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Ticket;
