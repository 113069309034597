import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import TimeUntil from "../components/timeUntil";
import Event from "../components/event";

function Body() {

  return (
    <>
      <div className="centerDiv">
        <div className="parallax">
          <div className="parallaxText">
            <h1 className="bom">
              Chill with Bomchi
            </h1>

            <p
              style={{
                color: "#fff",
                wordSpacing: "5px",
              }}
            >
              DEC. 23rd 2024{" "}
              <span style={{ fontSize: "25px" }}>
                |
              </span>{" "}
              2PM{" "}
            </p>
            <p className="k1 theme">
              YOUNG, WILD & FREE
            </p>
            {/* <p>Venue: Las Vence Doras Garden</p> */}

            {/* <a className="venue-link" href="https://maps.app.goo.gl/Ru7zNdvynrXWMQ9VA" 
            target="_blank" rel="noreferrer">View Location on Google Map</a> */}

            <div style={{ marginBottom: "5px" }}>
              <Link to="/ticket">
                <Button> BUY TICKETS </Button>
              </Link>

              <Link to="/vendor">
                <span
                  className="vendor"
                  style={{ marginLeft: "5px" }}
                >
                  {" "}
                  BECOME A VENDOR{" "}
                </span>
              </Link>
            </div>
            <TimeUntil />
          </div>
        </div>

        <Event />
      </div>
    </>
  );
}

export default Body;