import React, {
  useState,
  useEffect,
} from "react";
import Header from "../components/header.js";
import Container from "react-bootstrap/Container";
import jwtDecode from "jwt-decode";
import { useParams } from "react-router-dom";
import Image from "react-bootstrap/Image";
import checkImage from "../images/checked.png";
import xImage from "../images/close.png";
import Loader from "react-loader-spinner";

function VerifyChicken() {
  let [loading, setLoading] = useState(true);
  let [user, setUser] = useState({});
  let [f1, setVerifyF1] = useState("");
  let [f2, setVerifyF2] = useState("");
  let [f3, setVerifyF3] = useState({});

  const { reference } = useParams();

  async function issueTicket() {
    setLoading(true);
    const jwt = localStorage.getItem("token");
    const options = {
      method: "post",
      headers: new Headers({
        "x-auth-token": jwt,
      }),
      body: JSON.stringify({
        issuerName: user._name,
      }),
    };

    fetch(
      //   `http://localhost:5001/bomchi-39029/us-central1/app/api/approvechicken/${reference}`,
      //   options
      `https://us-central1-bomchi-39029.cloudfunctions.net/app/api/approvechicken/${reference}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.status == "success") {
          setVerifyF1((f1 = "DONE"));
        } else {
          setVerifyF1((f1 = "Try again"));
        }
        return;
      })
      .catch((err) => {
        setVerifyF1(
          (f3 =
            "Try again, make sure you have data")
        );
        // console.log(err, "error");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const jwt = localStorage.getItem("token");

    (async function () {
      setLoading(true);
      try {
        // console.log("token", jwt);
        if (jwt) {
          const user = await jwtDecode(jwt);
          setUser(user);
          // console.log("USER ", reference, "ref", user, !user);

          const options = {
            method: "post",
            headers: new Headers({
              "x-auth-token": jwt,
            }),
            body: JSON.stringify({
              issuerName: user._name,
            }),
          };

          const s = `https://us-central1-bomchi-39029.cloudfunctions.net/app/api/getchickenticket/${reference}`;
          // const l = `http://localhost:5001/bomchi-39029/us-central1/app/api/getchickenticket/${reference}`;
          fetch(s, options)
            .then((response) => {
              if (response.status == 401) {
                // console.log("i can see you");
                setVerifyF1(
                  (f1 =
                    "you are not authorized to view this page")
                );
              } else {
                // console.log("i got here", user._name);
                return response.json();
              }
            })
            .then((data) => {
              // console.log(data.status, data);
              if (data != undefined && user) {
                // console.log(data.status, "data status");
                if (data.status == "notfound") {
                  setVerifyF1(
                    (f1 = `** NOT FOUND - INVALID RECEIPT **`)
                  );
                } else if (
                  data.status == "used"
                ) {
                  setVerifyF2((f2 = data.pValue));
                } else if (
                  data.status == "invalid"
                ) {
                  setVerifyF1(
                    (f1 = "INVALID RECEIPT")
                  );
                } else if (data.status == "new") {
                  const words = {
                    1: "ONE (1)",
                    2: "TWO (2)",
                    3: "THREE (3)",
                    4: "FOUR (4)",
                    5: "FIVE (5)",
                    6: "SIX (6)",
                    7: "SEVEN (7)",
                    8: "EIGHT (8)",
                    9: "NINE (9)",
                    10: "TEN (10)",
                  };
                  data.qty = words[data.qty];
                  setVerifyF3((f3 = data));
                } else {
                  // console.log(data);
                  setVerifyF1(
                    "Check Internet Connection and refresh page"
                  );
                }
                // console.log(data, "is cool");
              } else
                return setVerifyF1(
                  (f1 = "ACCESS DENIED")
                );
            })
            .catch((err) => {
              setVerifyF1((f1 = "ACCESS DENIED"));
              // console.log(err, "error");
            })
            .finally(() => setLoading(false));
        } else {
          setVerifyF1(
            (f1 =
              "you are not authorized to view this page")
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // console.log(error);
        return setVerifyF1("ACCESS DENIED");
      }
    })();
  }, []);
  return (
    <>
      <Header />
      <div
        className="useticket"
        style={{
          minHeight: "50vh",
          color: "#000",
          backgroundColor: "#fff",
          textAlign: "center",
          padding: "20px",
          textTransform: "uppercase",
        }}
      >
        <Container>
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader
                type="ThreeDots"
                color="#2BAD60"
                height="100"
                width="100"
              />
            </div>
          )}
          {user.name && (
            <h2>
              ADMIN: {user._name || user._email}
            </h2>
          )}
          {!f3.name && f1.length > 2 && (
            <div>
              <h3>INVALID </h3>
              <Image
                src={xImage}
                style={{ marginBottom: "10px" }}
                width="50px"
              />
              <p>{f1}</p>
            </div>
          )}
          {!f3.name && f2.length > 2 && (
            <div>
              <h3>USED </h3>
              <Image
                src={xImage}
                style={{ marginBottom: "10px" }}
                width="50px"
              />
              <p>{f2}</p>
            </div>
          )}
          {f3.name && (
            <div style={{ padding: "30px" }}>
              <h2>VALID </h2>{" "}
              <Image
                src={checkImage}
                style={{ marginBottom: "10px" }}
                width="50px"
              />
              <h4>NAME: {f3.name}</h4>
              <h4>
                QUANTITY: {f3.qty} chicken&chips{" "}
              </h4>
              <p>Amount Paid: ₦{f3.amountPaid}</p>
              <div>
                <strong>PREVIOUS SCANS:</strong>{" "}
                {f3.scans
                  ? f3.scans.map((d, i) => (
                      <div
                        key={i}
                        className="viewCard"
                      >
                        <p>{d.name}</p>
                        {d.scanDate}
                      </div>
                    ))
                  : "NONE"}
              </div>
              <button
                onClick={issueTicket}
                disabled={
                  f1 === "DONE" || loading
                }
                style={{
                  padding: "15px",
                  marginTop: "10px",
                  backgroundColor:
                    f1 === "DONE"
                      ? "grey"
                      : "red",
                  color: "#fff",
                  fontSize: "20px",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader
                      type="ThreeDots"
                      color="#fff"
                      height="30"
                      width="30"
                    />
                  </div>
                ) : f1.length ? (
                  f1
                ) : (
                  "GIVE CHICKEN AND CHIPS"
                )}
              </button>
            </div>
          )}
        </Container>
      </div>
    </>
  );
}

export default VerifyChicken;
