import React, {
  useState,
  useEffect,
} from "react";
import Header from "../components/header.js";
import { Container } from "react-bootstrap";
import Loader from "react-loader-spinner";

function ViewUsers() {
  let [users, setUsers] = useState("");
  let [loading, setLoading] = useState(true);
  let [err, setErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    // let l =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/users";

    let s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/users";

    fetch(s)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, "is king");
        if (data) {
          const formDate = (i) => {
            let d = i.split("@")[0].split("/");
            d = `${d[1]}/${d[0]}/${d[2]}`;
            return new Date(d);
          };

          data.sort(function (a, b) {
            var val1 = formDate(a.date),
              val2 = formDate(b.date);
            return val2 - val1;
          });

          setUsers((users = data));
        }
      })
      .catch((err) => {
        setErr((err = true));
        // console.log(err, "error");
        alert(
          "Check your internet connection and Refresh the page "
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        minHeight: "100vh",
      }}
    >
      <Header />
      <div
        className="viewBody"
        style={{ padding: "20px" }}
      >
        <h2
          style={{
            textAlign: "center",
            textDecoration: "none",
          }}
        >
          {users.length} ADMIN USERS
        </h2>

        {loading && (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="ThreeDots"
              color="#2BAD60"
              height="100"
              width="100"
            />
          </div>
        )}
        <div>
          <Container>
            {loading == false && users.length
              ? users.map((user, i) => {
                return (
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      key={user.name}
                      style={{
                        width: "50%",
                        margin: "auto",
                        padding: "30px",
                      }}
                      className="viewCard"
                    >
                      <p>NAME: {user.name}</p>

                      <span
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        DATE CREATED:{" "}
                        {user.date}
                      </span>
                    </div>
                  </div>
                );
              })
              : null}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
