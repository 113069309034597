import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Image from "react-bootstrap/Image";
import logo from "../images/blogo3.png";
import Container from "react-bootstrap/Container";
import Loader from "react-loader-spinner";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";


function ViewTicket() {
  const ticRef = useRef();
  let [verified, setVerified] = useState(false);
  let [verifyFail, setVerifyFail] =
    useState(false);
  let [loading, setLoading] = useState(true);
  let [customerName, setName] = useState("");
  let [customerEmail, setEmail] = useState("");
  let [ref, setRef] = useState("");
  let [type, setType] = useState("");
  let [qty, setQuantity] = useState("");
  const { reference } = useParams();

  useEffect(() => {
    fetch(
      // `http://localhost:5001/bomchi-39029/us-central1/app/api/viewticket/${reference}`
      `https://us-central1-bomchi-39029.cloudfunctions.net/app/api/viewticket/${reference}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setVerified((verified = true));
        }
          setName((customerName = data.fname));
          setEmail((customerEmail = data.email));
          setRef((ref = data.ref));
          setType((type = data.type));
          setQuantity((qty = data.qty));
        
      })
      .catch((err) => {
        setVerifyFail((verifyFail = true));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Header />
      <div className="tics">
        <Container>
          <div className="ticstat">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Image
                src={logo}
                style={{
                  maxWidth: "40%",
                  margin: "auto",
                }}
              />
            </div>
            {loading && (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#2BAD60"
                  height="100"
                  width="100"
                />
              </div>
            )}
            {verified && (
              <div
                ref={ticRef}
                style={{ marginLeft: "30px" }}
              >
                <h6
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  Ticket: BOMCHI-{qty}-
                  {customerName}
                </h6>

                <div
                  id="qrcode"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <QRCode
                    size={200}
                    value={`https://chillwithbomchi.com/verify/${ref}`}
                  />
                </div>
                {/* <p>
                  Ticket Type:{" "}
                  <strong>{type}</strong>
                </p> */}
                <p>No of Tickets: {qty}</p>
                {/* <p>
                  See you on 24th December 2021 😬{" "}
                </p> */}
              </div>
            )}

          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ViewTicket;
