import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import Header from "../components/header.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function CreateTicket() {
  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fnameRef = useRef();
  const lnameRef = useRef();
  const passRef = useRef();
  const qtyRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const amountPaidRef = useRef();
  const purchaseCountRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();

    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/genticket";
    // const l =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/genticket";

    setLoading((loading = true));
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fname: fnameRef.current.value,
        lname: lnameRef.current.value,
        password: passRef.current.value,
        qty: qtyRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        amountPaid: amountPaidRef.current.value,
        purchaseCount: purchaseCountRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("response data", data);
        window.location.href =
       ` https://chillwithbomchi.com/viewticket/${data.ref}`;
      setLoading(false);
        // setErr(
        //   (err =
        //     nameRef.current.value +
        //     "'s ticket was created successfully.")
        // );
        document.getElementById("userticket").reset();
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        setLoading(false);
        setErr(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        minHeight: "100vh",
      }}
    >
      <Header />
      <div className="vendor centerDiv ">
        <h1>GENERATE TICKET </h1>

        <Form
          id="userticket"
          onSubmit={handleSubmit}
          className="vendorform centerDiv "
        >
          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>First Name</Form.Label>
            <Form.Control
              ref={fnameRef}
              type="text"
              placeholder="Name"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              ref={lnameRef}
              type="text"
              placeholder="Name"
              required
            />
          </Form.Group>

          <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Email address
                </Form.Label>
                <Form.Control
                  ref={emailRef}
                  type="email"
                  placeholder="Enter email"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicPhone"
              >
                <Form.Label>
                  Phone Number
                </Form.Label>
                <Form.Control
                  ref={phoneRef}
                  type="text"
                  placeholder="Phone Number"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicPhone"
              >
                <Form.Label>
                  Amount Paid
                </Form.Label>
                <Form.Control
                  ref={amountPaidRef}
                  type="text"
                  placeholder="Amount Paid"
                />
              </Form.Group>

          <Form.Group
                // style={{ backgroundColor: " #6c757d", borderColor: "#6c757d" }}
                className="mb-3"
                controlId="formBasicName"
              >
                <Form.Label>Quantity</Form.Label>
                <Form.Select
                  ref={qtyRef}
                  aria-label="Default select example"
                >
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option>
                  <option value="5">Five</option>
                  <option value="6">Six</option>
                  <option value="7">Seven</option>
                  <option value="8">Eight</option>
                  <option value="9">Nine</option>
                  <option value="10">Ten</option>
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicName"
              >
                <Form.Label>Purchase Count (Number of times user paid for tickets)</Form.Label>
                <Form.Select
                  ref={purchaseCountRef}
                  aria-label="Default select example"
                >
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicLink"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              ref={passRef}
              type="password"
              placeholder="Password"
              required
            />
          </Form.Group>

          

          {err.length > 0 && (
            <p
              style={{
                backgroundColor:
                  "rgba(0,0,0,0.7)",
                padding: "20px",
              }}
            >
              {" "}
              {err}
            </p>
          )}
          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#fff"
                  height="30"
                  width="30"
                />
              </div>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default CreateTicket;
