import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function Chicken() {
  const quantRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const emailConfirmRef = useRef();
  const phoneRef = useRef();
  const subRef = useRef();
  const regularPrice = 1600;

  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");
  let [amount, setAmount] =
    useState(regularPrice);

  let [qty, setQty] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function numberWithCommas(x) {
    return x
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onQtyChange = () => {
    setQty(
      (qty = Number(quantRef.current.value))
    );
    let currAmount =
      Number(regularPrice) * Number(qty);
    setAmount((amount = currAmount));
  };

  function onEmailChange() {
    if (
      (err =
        "Email doesn't match. Please ensure your email is correct.")
    ) {
      setErr((err = ""));
    }
  }

  function handleBuynow(e) {
    e.preventDefault();
    if (
      emailRef.current.value !==
      emailConfirmRef.current.value
    ) {
      setErr(
        (err =
          "Email doesn't match. Please ensure your email is correct.")
      );
      return;
    }
    if (
      nameRef.current.value.length < 1 ||
      emailRef.current.value.length < 5
    ) {
      setErr(
        (err =
          "Invalid Email or Name is too short")
      );
      return;
    }
    setLoading(true);
    const online =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/paychicken";

    // const local =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/paychicken";

    fetch(online, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount * 100,
        email: emailRef.current.value,
        name: nameRef.current.value,
        qty: quantRef.current.value,
        phone: phoneRef.current.value,
        sub: subRef.current.value,
        type: "chicken&chips",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href =
          data.data.authorization_url;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err, promo);
        alert(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }

  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#fff" }}>
        <Row
          style={{ width: "80%", margin: "auto" }}
        >
          <Col
            className="hide"
            sm
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Image
              style={{
                boxShadow:
                  "0 0 1px 0 rgba(0,0,0,0.5)",
              }}
              src="https://tingerea.sirv.com/bomchi/smile.jpg"
              width="100%"
            />
          </Col>
          <Col
            sm
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <h1 style={{ textAlign: "center" }}>
              BUY CHICKEN & CHIPS
            </h1>
            <p
              style={{
                color: "red",
                textAlign: "center",
              }}
            >
              Pay with Transfer | Card | Bank{" "}
            </p>
            <p>
              {" "}
              You can take a screenshot of your
              receipt immediately after payment.
              It will also be emailed to you.
              {/* Please don’t share your receipt with
              anyone. Feel free to fill out the
              enquiry form on the home page if you
              have any questions, issues or
              concerns and we’ll respond as soon
              as possible.{" "} */}
            </p>

            <Form onSubmit={handleBuynow}>
              <Form.Group
                // style={{ backgroundColor: " #6c757d", borderColor: "#6c757d" }}
                className="mb-3"
                controlId="formBasicName"
              >
                <Form.Label>Quantity</Form.Label>
                <Form.Select
                  onChange={onQtyChange}
                  ref={quantRef}
                  aria-label="Default select example"
                >
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option>
                  <option value="5">Five</option>
                  <option value="6">Six</option>
                  <option value="7">Seven</option>
                  <option value="8">Eight</option>
                  <option value="9">Nine</option>
                  <option value="10">Ten</option>
                </Form.Select>
              </Form.Group>

              <Form.Group
                style={{ marginBottom: "5px" }}
              >
                <Form.Label>
                  <h5>
                    {" "}
                    Price: ₦
                    {numberWithCommas(amount)}
                  </h5>
                </Form.Label>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicFname"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  ref={nameRef}
                  type="text"
                  placeholder="Name"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Email address
                </Form.Label>
                <Form.Control
                  ref={emailRef}
                  type="email"
                  placeholder="Enter email"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Confirm Email address
                </Form.Label>
                <Form.Control
                  ref={emailConfirmRef}
                  type="email"
                  onChange={onEmailChange}
                  placeholder="Confirm email"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicPhone"
              >
                <Form.Label>
                  Phone Number
                </Form.Label>
                <Form.Control
                  ref={phoneRef}
                  type="text"
                  placeholder="Phone Number"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  ref={subRef}
                  type="checkbox"
                  label="Subscribe to Mailing list"
                />
              </Form.Group>

              <p style={{ color: "red" }}>
                {err}
              </p>

              <div
                className="d-grid gap-2"
                style={{ marginBottom: "20px" }}
              >
                <Button
                  disabled={loading}
                  variant="secondary"
                  size="lg"
                  type="submit"
                >
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="#fff"
                        height="30"
                        width="30"
                      />
                    </div>
                  ) : (
                    `PAY ₦${numberWithCommas(
                      amount
                    )}`
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default Chicken;
