import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function Artists() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nameRef = useRef();
  const linkRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const instaRef = useRef();
  // const photoRef = useRef();
  const promoRef = useRef();
  const comRef = useRef();

  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (emailRef.current.value.length < 5) {
      setErr(
        (err = "Please enter a valid email ")
      );
      return;
    }
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/artist";
    // const l = "http://localhost:5001/bomchi-api/us-central1/app/api/artist";

    setLoading((loading = true));
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: nameRef.current.value,
        link: linkRef.current.value,
        phone: phoneRef.current.value,
        email: emailRef.current.value,
        insta: instaRef.current.value,
        // photo: photoRef.current.value,
        promo: promoRef.current.value,
        com: comRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("response data", data);

        setErr(
          (err =
            nameRef.current.value +
            ", Thanks for your interest, We will get back to you soon 😬")
        );
        document.getElementById("artist").reset();
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        alert(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }
  return (
    <div>
      <Header />
      <div className="vendor centerDiv ">
        <h1>ENTERTAINERS </h1>

        <Form
          id="artist"
          onSubmit={handleSubmit}
          className="vendorform centerDiv "
        >
          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={nameRef}
              type="text"
              placeholder="Name"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicLink"
          >
            <Form.Label>
              Link To Work (A song / Video)
            </Form.Label>
            <Form.Control
              ref={linkRef}
              type="text"
              placeholder="Link"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicPhone"
          >
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              ref={phoneRef}
              type="text"
              placeholder="Phone Number"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicAddress"
          >
            <Form.Label>Email address</Form.Label>
            <Form.Control
              ref={emailRef}
              type="email"
              placeholder="Enter email"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicLink"
          >
            <Form.Label>
              Instagram Link
            </Form.Label>
            <Form.Control
              ref={instaRef}
              placeholder="Instagram Link"
              required
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>
              Promo Code <br />
              (Give fans 5% discount
              <br /> on Regular Tickets)
            </Form.Label>
            <Form.Control
              ref={promoRef}
              placeholder="Your name / stage name "
              required
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>Picture Upload</Form.Label>
            <Form.Control
              ref={photoRef}
              placeholder="A nice picture for a flyer"
              required
            />
          </Form.Group> */}
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              ref={comRef}
              as="textarea"
              placeholder="Leave a comment / Drop a question if any."
            />
          </Form.Group>
          {err.length > 0 && (
            <p
              style={{
                backgroundColor:
                  "rgba(0,0,0,0.7)",
                padding: "20px",
              }}
            >
              {" "}
              {err}
            </p>
          )}
          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#fff"
                  height="30"
                  width="30"
                />
              </div>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Form>
      </div>

      <Footer />
    </div>
  );
}

export default Artists;
