import React, {
  useState,
  useEffect,
} from "react";
import Header from "../components/header.js";
import jwtDecode from "jwt-decode";
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Loader from "react-loader-spinner";

function formatNumber(numberString) {
  // Parse the number
  const number = parseFloat(numberString);

  // Remove trailing zeros
  const trimmedNumber = number.toString().replace(/0*$/, "");

  // Add comma separators
  const formattedNumber = trimmedNumber.replace(/\d(?=(\d{3})+$)/g, "$&,");

  // Prepend naira sign
  return `₦${formattedNumber}`;
}

function formatNumber2(numberString1) {
  // Parse the number
  let number = parseFloat(numberString1);

  // Convert the number to a string
  let numberString = number.toString();

  // Add dot before last two digits if length is greater than 3
  if (numberString.length > 3) {
    numberString = numberString.slice(0, -2) + "." + numberString.slice(-2);
  }

  // Add comma separators
  let formattedNumber = numberString.replace(/\d(?=(\d{3})+$)/g, "$&,");

  // Prepend naira sign
  return `₦${formattedNumber}`;
}

function ViewTickets() {
  let [user, setUser] = useState({});
  let [tickets, setTickets] = useState("");
  let [initialData, setInitialData] = useState([]);
  let [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  let [, setErr] = useState("");

  useEffect(() => {
    const jwt = localStorage.getItem("token");
    if (jwt) {
      const user = jwtDecode(jwt);
      setUser(user);
      // console.log("user", user);
    }
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    handleSearch(value);
  };

  const handleClear = () => {
    setSearchValue('');
    setTickets(initialData)
  }

  const handleSearch = (searchValue) => {
    if (searchValue.length > 0) {
      const filtered = initialData.filter(
        (ticket) =>
          ticket.fname.toLowerCase().replace(/ /g, '').includes(searchValue.replace(/ /g, '').toLowerCase()) ||
          ticket.lname.toLowerCase().replace(/ /g, '').includes(searchValue.replace(/ /g, '').toLowerCase()) ||
          ticket?.authName?.toLowerCase().replace(/ /g, '').includes(searchValue.replace(/ /g, '').toLowerCase()) ||
          ticket.ref.includes(searchValue.replace(/ /g, '').toLowerCase())
      );
      setTickets(filtered);
    } else {
      setTickets(initialData); // Show all data when searchValue is empty
    }
  };

  function handleViewTicket(ref) {
    try {
      window.open(
        `https://chillwithbomchi.com/viewticket/${ref}`,
        "_blank"
      );
    } catch (err) {
      // console.log(err);
      setErr(
        "Please check your internet connection and try again"
      );
    }
  }

  function handleIssueTicket(ref) {
    try {
      window.open(
        `https://chillwithbomchi.com/verify/${ref}`,
        "_blank"
      );
    } catch (err) {
      // console.log(err);
      setErr(
        "Please check your internet connection and try again"
      );
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    // let l =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/tickets";

    let s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/tickets";

    fetch(s)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data[0], "is king");
        if (data) {

          const filteredData = data
            .sort(function (a, b) {
              const val1 = new Date(a.date),
                val2 = new Date(b.date);
              return val2 - val1;
            })
            .filter(item => new Date(item.date).getFullYear() === 2023 && item.ref);
          setTickets(filteredData);
          setInitialData(filteredData);
        }
      })
      .catch((err) => {
        setErr(err);
        console.log(err, "error");
        alert(
          "Check your internet connection and Refresh the page "
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        minHeight: "100vh",
      }}
    >
      <Header />
      <div
        className="viewBody"
        style={{ padding: "20px" }}
      >
        <h2
          style={{
            textAlign: "center",
            textDecoration: "none",
          }}
        >
          {/* {(user._name === "BOMCHIADMIN1" ||
                user._name === "DONALD") && tickets.length} TICKETS */}
                FIND TICKET
        </h2>
        <div className="center-div">
          <input
            type="text"
            placeholder="Search by name or reference"
            value={searchValue}
            onChange={handleChange}
            className="search-input"
          />
          <button onClick={handleClear} className="clear-button">Clear</button>
        </div>
        {
          searchValue.length > 0 && <p className="center-div">Found {tickets.length % 10} result(s) for {searchValue}</p>
        }

        {loading && (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading ...
            <Loader
              type="ThreeDots"
              color="#2BAD60"
              height="100"
              width="100"
            />
          </div>
        )}
        <div>
          <Container>

            {loading === false && (tickets.length < initialData.length)
              ? tickets.map((ticket) => {
                return (
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                    key={ticket.ref}
                  >
                    <div
                      // key={ticket.ref}
                      style={{
                        width: "50%",
                        margin: "auto",
                        padding: "30px",
                      }}
                      className="viewCard"
                    >
                      <p>Reference: {ticket.ref}</p>
                      <p>Name: {ticket.fname} {ticket.lname}</p>
                      {ticket.channel === 'card' ? <p>Card Name: {ticket.authName}</p> : ''}
                      <p>Channel: {ticket.channel}</p>
                      <p>Amount Paid: {formatNumber(ticket.amount)} for {ticket.noOfTics} ticket(s)</p>
                      {/* <p>No of Tickets: {ticket.noOfTics}</p> */}
                      
                      <Button
                        variant="primary"  // Set the variant to 'primary' for a blue background
                        onClick={() => handleViewTicket(ticket.ref)}
                      >
                        View Ticket
                      </Button>
                      <Button
                      style={{marginLeft:'20px'}}
                        variant="danger"  // Set the variant to 'primary' for a blue background
                        onClick={() => handleIssueTicket(ticket.ref)}
                        sty
                      >
                        Issue Ticket
                      </Button> <br />
                      <span
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        DATE PURCHASED:{" "}
                        {ticket.date}
                      </span>
                    </div>
                  </div>
                );
              })
              : null}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ViewTickets;
