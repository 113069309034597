import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function Enquire() {
  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  const nameRef = useRef();
  const phoneRef = useRef();
  const comRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    if (phoneRef.current.value.length < 3) {
      setErr(
        (err =
          "Please enter a valid phone number / email")
      );
      return;
    }
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/enquire";
    //  const l =
    //    "http://localhost:5001/bomchi-39029/us-central1/app/api/enquire";

    setLoading((loading = true));
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: nameRef.current.value,
        phone: phoneRef.current.value,
        com: comRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("response data", data);

        setErr(
          (err =
            nameRef.current.value +
            ", Thanks for your message, We will reply shortly.")
        );
        document
          .getElementById("enquire")
          .reset();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }

  return (
    <>
      <div
        style={{ width: "90%", margin: "auto" }}
      >
        <h4
          style={{
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <strong>
            Don't be a Stranger! <br />
            Say Hi
          </strong>{" "}
        </h4>
        <Form
          onSubmit={handleSubmit}
          id="enquire"
          className="vendorform  "
        >
          <Form.Group
            className="mb-3"
            controlId="formBasicName"
          >
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={nameRef}
              type="text"
              placeholder="Name"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            c
            controlId="formBasicEmail"
          >
            <Form.Label>
              Phone Number / Email
            </Form.Label>
            <Form.Control
              ref={phoneRef}
              type="text"
              placeholder="Phone Number / Email"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" c>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              ref={comRef}
              as="textarea"
              placeholder="Leave a comment / Drop a question if any."
              required
            />
          </Form.Group>
          {err.length > 0 && (
            <p
              style={{
                padding: "20px",
              }}
            >
              {" "}
              {err}
            </p>
          )}
          <Button
            style={{
              display: "block",
              margin: "auto",
            }}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#fff"
                  height="30"
                  width="30"
                />
              </div>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default Enquire;
