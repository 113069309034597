import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
} from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import BuyTicket from "./pages/BuyTicket";
import Ticket from "./pages/Ticket";
import ViewTicket from "./pages/ViewTicket";
import Chicken from "./pages/Chicken";
import Vendor from "./pages/Vendor";
import Admin from "./pages/Admin";
import Event from "./components/event";
import Verify from "./pages/Verify";
// import ImageGallery from "./pages/Gallery";
import Artists from "./pages/Artists";
import NotFound from "./pages/NotFound";
import VerifyChicken from "./pages/VerifyChicken";

import ViewVendors from "./adminPages/viewVendors.js";
import ViewPerformers from "./adminPages/viewPerformers";
import ViewEnquires from "./adminPages/viewEnquires";
import ViewUsers from "./adminPages/ViewUsers";
import CreateUser from "./adminPages/CreateUser";
import CreateTicket from "./adminPages/createTicket"
import ViewTickets from "./adminPages/viewTickets.js";

import "./App.css";
import jwtDecode from "jwt-decode";

const ImageGallery = lazy(() => import('./pages/Gallery'));

function App() {
  let [, setUser] = useState({});

  useEffect(() => {
    const jwt = localStorage.getItem("token");
    if (jwt) {
      const user = jwtDecode(jwt);
      setUser(user);
      // console.log("user", user);
    }
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/ticket"
            component={BuyTicket}
          />
          <Route
            exact
            path="/ticketstatus"
            component={Ticket}
          />
          <Route
            exact
            path="/viewticket/:reference"
            component={ViewTicket}
          />
          <Route
            exact
            path="/chicken"
            component={Chicken}
          />
          <Route
            exact
            path="/vendor"
            component={Vendor}
          />
          <Route
            exact
            path="/about"
            component={Event}
          />
          <Route
            exact
            path="/admin22"
            component={Admin}
          />
          <Route
            exact
            path="/gallery"
            component={ImageGallery}
          />
          <Route
            exact
            path="/notfound"
            component={NotFound}
          />
          <Route
            exact
            path="/verify/:reference"
            component={Verify}
          />
          <Route
            exact
            path="/verifychicken/:reference"
            component={VerifyChicken}
          />
          <Route
            exact
            path="/entertainer"
            component={Artists}
          />
          {/* {user._name && ( */}
            <>
              <Route
                exact
                path="/vv"
                component={ViewVendors}
              />
              <Route
                exact
                path="/vp"
                component={ViewPerformers}
              />
              <Route
                exact
                path="/ve"
                component={ViewEnquires}
              />
              <Route
                exact
                path="/vu"
                component={ViewUsers}
              />
              <Route
                exact
                path="/cu"
                component={CreateUser}
              />
              <Route
                exact
                path="/ct"
                component={CreateTicket}
              />
              <Route
                exact
                path="/vt"
                component={ViewTickets}
              />
            </>
          {/* )} */}
          <Redirect to="/notfound" />
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
