import React, {
  useState,
  useEffect,
} from "react";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import Container from "react-bootstrap/Container";
import Loader from "react-loader-spinner";

function Viewperformers() {
  let [performers, setPerformers] = useState("");
  let [loading, setLoading] = useState(true);
  // let [err, setErr] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch(
      // `http://localhost:5001/bomchi-39029/us-central1/app/api/performers`,
      `https://us-central1-bomchi-39029.cloudfunctions.net/app/api/performers`
    )
      .then((response) => response.json())
      .then((data) => {
        // let arr = [];
        // data.map((d) => arr.push(d.promo));
        // console.log(arr);

        if (data) {
          const formDate = (i) => {
            let d = i.split("@")[0].split("/");
            d = `${d[1]}/${d[0]}/${d[2]}`;
            return new Date(d);
          };

          data.sort(function (a, b) {
            var val1 = formDate(a.date),
              val2 = formDate(b.date);
            return val2 - val1;
          });

          setPerformers((performers = data));
        }
      })
      .catch((err) => {
        // setErr((err = true));
        // console.log(err, "error");
        alert(
          "Check your internet connection and Refresh the page "
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Header />
      <div
        className="viewBody"
        style={{ padding: "20px" }}
      >
        <h2
          style={{
            textAlign: "center",
            textDecoration: "none",
          }}
        >
          {performers.length} Registered
          Entertainers
        </h2>

        {loading && (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="ThreeDots"
              color="#2BAD60"
              height="100"
              width="100"
            />
          </div>
        )}

        <Container>
          {loading == false && performers.length
            ? performers.map((performer, i) => {
                return (
                  <div
                    key={i}
                    style={{ padding: "30px" }}
                    className="viewCard"
                  >
                    <p>NAME: {performer.name}</p>
                    <p>
                      LINK TO WORK:{" "}
                      <a
                        href={performer.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {performer.link}
                      </a>
                    </p>

                    <p>
                      PHONE NO:
                      <a
                        href={
                          "tel:" + performer.phone
                        }
                      >
                        {performer.phone}
                      </a>
                    </p>
                    <p>
                      EMAIL:{" "}
                      <a
                        href={
                          "mailto:" +
                          performer.email
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {performer.email}
                      </a>
                    </p>
                    <p>
                      INSTAGRAM LINK:{" "}
                      <a
                        href={performer.insta}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {performer.insta}
                      </a>
                    </p>

                    <p>
                      PROMO: {performer.promo}
                    </p>
                    <p>
                      COMMENTS: {performer.comm}
                    </p>
                    <p>Date: {performer.date}</p>
                  </div>
                );
              })
            : null}
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Viewperformers;
