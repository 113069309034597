import React from "react";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";
import s1 from "../images/g1.jpg";
import s2 from "../images/g2.jpg";
import s3 from "../images/g3.jpg";
import s4 from "../images/g4.jpg";
import s5 from "../images/g5.jpg";
import Enquire from "./enquire";

function event() {
  return (
    <div id="about" style={{ backgroundColor: "#fff" }}>
      <Row className="gx-5" style={{ width: "100%", margin: "auto" }}>
        <Col sm="6" style={{ marginTop: "3vh" }}>
          {/* <Image className="bi" src={bodyImage} width="100%" /> */}
          <Carousel className="bi">
            <Carousel.Item>
              <Image src={s1} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <Image src={s2} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <Image src={s3} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <Image src={s4} width="100%" />
            </Carousel.Item>

            <Carousel.Item>
              <Image src={s5} width="100%" />
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col sm="6" style={{ marginTop: "5vh" }}>

          <article
            className="body-text"
            style={{
              fontWeight: "500px",
            }}
          >
            <header>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "500px",
                fontSize: "40px",
              }}
            >
              THE EVENT
            </h2>
            </header>
            <section>
            <p className="ml">
              Chill with Bomchi is a sit-out and connect event that aims at
              satisfying a general need for relaxation. This event is designed
              to bring people from different parts of the city together, serve
              them the sumptuous bomchi chicken and chips and engage their minds
              and hands (playing games and interacting with people).
            </p>
            <p className="ml">
              This event is an avenue to showcase other products in the city,
              introducing the contemporary creativity of Port Harcourt arts,
              featuring local performers, entertainers and many other city
              dwellers. Come, let us ‘SUPPORT OUR OWN’ in Port Harcourt.
            </p>
            <p className="ml">
              Chill with bomchi is not solely for the aim of satisfying everyone
              that attends, but also those that cannot afford to get this
              satisfaction. 15% of the total profit from this event is
              donated to charity (feeding on the street, visiting orphanages and
              nursing homes to celebrate with them in this season of good
              tidings).
            </p>
            </section>
          </article>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <div>
            <Enquire />
          </div>
        </Col>
        <Col sm={6}>
          <video
            width="100%"
            height={window.screen.width > 500 ? 480 : 380}
            controls
            preload="metadata"
            poster="https://firebasestorage.googleapis.com/v0/b/ucis-1f265.appspot.com/o/bomchi%2F11.jpg?alt=media&token=bca24c0a-d5c6-4976-9687-8f788f442c4f"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/ucis-1f265.appspot.com/o/bomchi%2FBOM-VID.mp4?alt=media&token=29dfca7c-bfa0-474f-905d-8817f115a3ae"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Col>
      </Row>
    </div>
  );
}

export default event;
