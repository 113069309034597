import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

function Vendor() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fnameRef = useRef();
  const lnameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const bizRef = useRef();
  const comRef = useRef();

  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (emailRef.current.value.length < 5) {
      setErr(
        (err = "Please enter a valid email ")
      );
      return;
    }
    const s =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/vendor";
    // const l = "http://localhost:5001/bomchi-api/us-central1/app/api/vendor";

    setLoading((loading = true));
    fetch(s, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fname: fnameRef.current.value,
        lname: lnameRef.current.value,
        phone: phoneRef.current.value,
        email: emailRef.current.value,
        biz: bizRef.current.value,
        com: comRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("response data", data);

        setErr(
          (err =
            fnameRef.current.value +
            ", Thanks for your interest, We will get back to you soon 😬")
        );
        document.getElementById("vendor").reset();
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
        alert(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }
  return (
    <div>
      <Header />
      <div className="vendor centerDiv ">
        <h1>Fill Vendor Interest Form </h1>

        <Form
          id="vendor"
          onSubmit={handleSubmit}
          className="vendorform centerDiv "
        >
          <Form.Group
            className="mb-3"
            controlId="formBasicFirstName"
          >
            <Form.Label>First Name</Form.Label>
            <Form.Control
              ref={fnameRef}
              type="text"
              placeholder="First Name"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicLastName"
          >
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              ref={lnameRef}
              type="text"
              placeholder="Last Name"
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicPhone"
          >
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              ref={phoneRef}
              type="text"
              placeholder="Phone Number"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
          >
            <Form.Label>Email address</Form.Label>
            <Form.Control
              ref={emailRef}
              type="email"
              placeholder="Enter email"
              required
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="formBasicTextArea1"
          >
            <Form.Label>
              Business Details
            </Form.Label>
            <Form.Control
              ref={bizRef}
              as="textarea"
              placeholder="Please tell us about your business"
              style={{ height: "100px" }}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              ref={comRef}
              as="textarea"
              placeholder="Leave a comment / Drop a question if any."
            />
          </Form.Group>
          {err.length > 0 && (
            <p
              style={{
                backgroundColor:
                  "rgba(0,0,0,0.7)",
                padding: "20px",
              }}
            >
              {" "}
              {err}
            </p>
          )}
          <Button
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#fff"
                  height="30"
                  width="30"
                />
              </div>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Form>
      </div>

      <Footer />
    </div>
  );
}

export default Vendor;
