import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
// import bodyImage from "../images/5.jpg";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loader from "react-loader-spinner";

const newProcessingFee = (amount, processingFeePercentage) => (amount * processingFeePercentage) / 100 + 100;

function BuyTicket() {
  const tictypeRef = useRef();
  const quantRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const emailConfirmRef = useRef();
  const phoneRef = useRef();
  const subRef = useRef();
  const regularTicketPrice = 4500;
  const processingFeePercentage = 3.9;
  const promoRef = useRef();

  let [loading, setLoading] = useState(false);
  let [err, setErr] = useState("");
  let [amount, setAmount] = useState(
    regularTicketPrice
  );
  const [processingFee, setProcessingFee] = useState(Math.floor(newProcessingFee(amount, processingFeePercentage)));
  let [ticType, setTikTyp] = useState(
    regularTicketPrice
  );
  let [qty, setQty] = useState(1);
  let [promo, setPromo] = useState(false);
  let [promoCount, setPromoCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function numberWithCommas(x) {
    return x
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const codes = {
    bomchi2021: false,
  };

  // const applyPromo = () => {
  //   if (ticType != 5000 && promoCount < 1) {
  //     setPromo(
  //       (promo = promoRef.current.value
  //         .toLowerCase()
  //         .replace(/\s/g, ""))
  //     );
  //     if (codes[promo]) {
  //       setErr((err = ""));
  //       setPromoCount((promoCount = 1));
  //       let temp = 0.05 * amount;
  //       let currAmount = amount - temp;
  //       setAmount((amount = currAmount));
  //     } else {
  //       setErr((err = "Invalid Promo Code"));
  //     }
  //   }
  //   if (ticType == 5000) {
  //     setPromoCount((promoCount = 0));
  //     setErr(
  //       (err =
  //         "Promo Code valid for only regular tickets.")
  //     );
  //   }
  // };

  const onTicTypChange = () => {
    setErr((err = ""));
    setTikTyp(
      (ticType = Number(tictypeRef.current.value))
    );

    setAmount(
      (amount = Number(ticType) * Number(qty))
    );
    setProcessingFee(Math.floor(newProcessingFee(amount, processingFeePercentage)));
  };

  const onQtyChange = () => {
    setQty(
      (qty = Number(quantRef.current.value))
    );
    let currAmount =
      Number(ticType) * Number(qty);
    setAmount((amount = currAmount));
    setProcessingFee(Math.floor(newProcessingFee(amount, processingFeePercentage)));
  };

  function onEmailChange() {
    if (
      (err =
        "Email doesn't match. Please ensure your email is correct.")
    ) {
      setErr((err = ""));
    }
  }

  function handleBuynow(e) {
    e.preventDefault();
    if (
      emailRef.current.value !==
      emailConfirmRef.current.value
    ) {
      setErr(
        (err =
          "Email doesn't match. Please ensure your email is correct.")
      );
      return;
    }
    if (
      fnameRef.current.value.length < 1 ||
      lnameRef.current.value.length < 1 ||
      emailRef.current.value.length < 5
    ) {
      setErr(
        (err =
          "Invalid Email or Name is too short")
      );
      return;
    }
    setLoading(true);
    const newS =
      "https://us-central1-bomchi-39029.cloudfunctions.net/app/api/pay";

    // const local =
    //   "http://localhost:5001/bomchi-39029/us-central1/app/api/pay";

    fetch(newS, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: (amount + processingFee) * 100,
        email: emailRef.current.value,
        first_name: fnameRef.current.value,
        last_name: lnameRef.current.value,
        ttype: tictypeRef.current.value,
        qty: quantRef.current.value,
        phone: phoneRef.current.value,
        sub: subRef.current.value,
        promo: "",
        // promo: promoRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href =
          data.data.authorization_url;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err, promo);
        alert(
          "Please check your internet connection and refresh the page to try again."
        );
      });
  }

  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#fff" }}>
        <Row
          style={{ width: "80%", margin: "auto" }}
        >
          <Col
            className="hide"
            sm
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Image
              style={{
                boxShadow:
                  "0 0 1px 0 rgba(0,0,0,0.5)",
              }}
              src="https://firebasestorage.googleapis.com/v0/b/ucis-1f265.appspot.com/o/bomchi%2F15.jpg?alt=media&token=fdbc0392-44e3-4c5f-b98b-8bd5037031bb"
              width="100%"
            />
          </Col>
          <Col
            sm
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <h1 style={{ textAlign: "center" }}>
              BUY TICKET
            </h1>
            <p
              style={{
                color: "red",
                textAlign: "center",
              }}
            >
              Pay with Transfer | Card | Bank{" "}
            </p>
            <p>
              {" "}
              You can take a screenshot of your
              ticket immediately after payment. It
              will also be emailed to you. Please
              don’t share your ticket with anyone.
              Feel free to fill out the enquiry
              form on the home page if you have
              any questions, issues or concerns
              and we’ll respond as soon as
              possible.{" "}
            </p>

            <Form onSubmit={handleBuynow}>
              <Form.Group
                className="mb-3"
                controlId="formBasicName"
              >
                <Form.Label>
                  Ticket Type 
                  <Button
                        variant="secondary"
                        disabled
                        style={{fontSize: '15px', marginLeft: '15px', backgroundColor:'red'}}
                      >
                        Early bird ends 22nd December
                      </Button>
                </Form.Label>
                <Form.Select
                  onChange={onTicTypChange}
                  ref={tictypeRef}
                  // defaultValue={4500}
                  aria-label="Default select example"
                >
                  <option
                    disabled
                    value={regularTicketPrice}
                  >
                    EARLY BIRD
                  </option>
                  <option value="4500">
                    AT THE VENUE
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group
                // style={{ backgroundColor: " #6c757d", borderColor: "#6c757d" }}
                className="mb-3"
                controlId="formBasicName"
              >
                <Form.Label>Quantity</Form.Label>
                <Form.Select
                  onChange={onQtyChange}
                  ref={quantRef}
                  aria-label="Default select example"
                >
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option>
                  <option value="5">Five</option>
                  <option value="6">Six</option>
                  <option value="7">Seven</option>
                  <option value="8">Eight</option>
                  <option value="9">Nine</option>
                  <option value="10">Ten</option>
                </Form.Select>
              </Form.Group>

              <Form.Group
                style={{ marginBottom: "5px" }}
              >
                <Form.Label>
                  <h5>
                    {" "}
                    Price: ₦
                    {numberWithCommas(amount)}
                  </h5>
                </Form.Label>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicFname"
              >
                <Form.Label>
                  First Name
                </Form.Label>
                <Form.Control
                  ref={fnameRef}
                  type="text"
                  placeholder="First Name"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicLname"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  ref={lnameRef}
                  type="text"
                  placeholder="Last Name"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Email address
                </Form.Label>
                <Form.Control
                  ref={emailRef}
                  type="email"
                  placeholder="Enter email"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicEmail"
              >
                <Form.Label>
                  Confirm Email address
                </Form.Label>
                <Form.Control
                  ref={emailConfirmRef}
                  type="email"
                  onChange={onEmailChange}
                  placeholder="Confirm email"
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicPhone"
              >
                <Form.Label>
                  Phone Number
                </Form.Label>
                <Form.Control
                  ref={phoneRef}
                  type="text"
                  placeholder="Phone Number"
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  ref={subRef}
                  type="checkbox"
                  label="Subscribe to Mailing list"
                />
              </Form.Group>
              <div className="summary">
                <p>SUBTOTAL: ₦{numberWithCommas(amount)}</p>
                <p>TRANSACTION FEES: ₦{processingFee}</p>
                <p>TOTAL: ₦{numberWithCommas(amount + processingFee)}</p>
              </div>


              {/* <Form.Group
                className="mb-3"
                controlId="formBasicPhone"
              >
                <Form.Label>
                  Promo Code
                </Form.Label>
                <Form.Control
                  ref={promoRef}
                  type="text"
                  placeholder="Promo Code"
                />
                <Button
                  style={{ marginTop: "5px" }}
                  disabled={loading}
                  variant="secondary"
                  // size="lg"
                  onClick={() => applyPromo()}
                >
                  APPLY PROMO
                </Button>
              </Form.Group> */}
              <p style={{ color: "red" }}>
                {err}
              </p>
              {codes[promo] &&
                promo &&
                tictypeRef.current.value !==
                "5000" &&
                promoCount == 1 && (
                  <p>
                    Promo Code:{" "}
                    {promoRef.current.value}{" "}
                    applied.{" "}
                  </p>
                )}
              <div
                className="d-grid gap-2"
                style={{ marginBottom: "20px" }}
              >
                <Button
                  disabled={loading}
                  variant="secondary"
                  size="lg"
                  type="submit"
                >
                  {loading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="#fff"
                        height="30"
                        width="30"
                      />
                    </div>
                  ) : (
                    `PAY ₦${numberWithCommas(
                      amount + processingFee
                    )}`
                  )}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default BuyTicket;
